$darkPurple: #6B62C4;
$lightPurple: #C4C0E7;
$blue: #1e34c5;
$deepBlue: #141256;
$green: #39aa61;
$carrot: #F29918;
$orange: #CC4F31;
$dark-brown: #4C333E;
$kaki: #6BA335;
$darkGrey: #535A60;
$lightGrey: #F4F4F4;
$white: #EEEEEE;

.has-text-green {
    color: $green;
}

.has-text-orange {
    color: $orange;
}
