@charset "utf-8";
@import "./styles/colors.scss";
@import "./styles/fonts.scss";

// Update Bulma's global variables
$primary: $green;
$info: $deepBlue;
$success: $blue;
$warning: $carrot;
$danger: $orange;
$link: $blue;

$tabs-boxed-link-active-background-color: $green;
$tabs-link-hover-border-bottom-color	: $green;
$tabs-toggle-link-active-color	: $green;
$footer-background-color: $white;
$footer-padding: 3rem 1.5rem;

@import "~bulma/bulma";

.columns {
    margin: 0;
}

.spinning {
    animation-name: spin;
    animation-duration: 10000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.md-render {
    h1 {
        color: $title-color;
        font-size: $title-size;
        font-weight: $title-weight;
        line-height: $title-line-height;
        margin-bottom: 1rem;
    }
    h2 {
        color: $subtitle-color;
        font-size: $subtitle-size;
        font-weight: $subtitle-weight;
        line-height: $subtitle-line-height;
        margin-bottom: 0.75rem;
    }
    p {
        overflow-wrap: break-word;
    }
}
