@import "../../../index";

#login-page, #signup-page{
  background-image: url("../../../assets/images/Others/login-signup-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;

  min-height: 100vh;
  height: auto;
  margin: 0;
}

#login-page {
  .box {
    margin-top: 5rem;
  }
  .avatar {
    margin-top: -70px;
    padding-bottom: 20px;
  }
  .avatar img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
    box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
    background: lighten($white, 3);
  }
  input {
    font-weight: 300;
  }
  p {
    font-weight: 700;
  }
  p.subtitle {
    padding-top: 1rem;
  }
  a { color: inherit; }
}
