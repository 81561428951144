@font-face {
    font-family: 'PoetsenOne-Regular';
    src: url('../assets/fonts/PoetsenOneRegular/PoetsenOne-Regular.eot') format('embedded-opentype'),  url('../assets/fonts/PoetsenOneRegular/PoetsenOne-Regular.woff') format('woff'), url('../assets/fonts/PoetsenOneRegular/PoetsenOne-Regular.ttf')  format('truetype'), url('../assets/fonts/PoetsenOneRegular/PoetsenOne-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Adam';
    src: url('../assets/fonts/Adam/Adam.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/Adam/Adam.otf')  format('opentype'),
           url('../assets/fonts/Adam/Adam.woff') format('woff'), url('../assets/fonts/Adam/Adam.ttf')  format('truetype'), url('../assets/fonts/Adam/Adam.svg#Adam') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  

// $family-sans-serif: 'Adam';
// $family-primary: 'Adam';
$family-secondary: 'Adam';
// $family-monospace: 'Adam';
// $family-code: 'Adam';